import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { addContact, viewContact } from '../../db/db'
import NavBar from '../NavBar/NavBar'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useBodyWidth } from '../hook/useBodyWidth'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const Banner = () => {
    const [open, setOpen] = React.useState(false);
    const withBodyWidth = useBodyWidth();

    const MySwal = withReactContent(Swal)


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const resetPage = () => {
        window.location.reload(true);
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const name = e.target.elements.name.value
        const lastname = e.target.elements.lastname.value
        const email = e.target.elements.email.value
        const phone = e.target.elements.phone.value
        const data = {
            name,
            lastname,
            email,
            phone
        }
        addContact(data).then(() => {
            setOpen(true);
            MySwal.fire({
                title: <p>Gracias por sumarte. Alguien del equipo se va a comunicar con vos</p>,
                html: <div id="whatsapp" style={{ maxWidth: '60px', maxHeight: '60px', overflow: 'hidden', marginLeft: '45%', }}>
                    < a href="https://api.whatsapp.com/send?phone=543416804067" target="_blank" >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <WhatsAppIcon style={{ fontSize: 60, color: '#25D366' }} />
                        </Box>
                    </a >
                </div >,
                didOpen: () => {
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 13500);

                },
            })
        }
        )


    }

    return (
        <Container maxWidth="xl" disableGutters={true} >
            <NavBar />
            <Grid item
                sx={{
                    backgroundImage: withBodyWidth > 900 ? `url(./portada.jpg)` : `url(./portada-mobile.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    height: '100vh',
                    marginTop: '0px',
                }}
            >
            </Grid >
            <Grid item xs={12}
                sx={{
                    minWidth: '300px',
                    maxWidth: 'calc(100vw - 40px)',
                    position: 'absolute',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    borderRadius: '10px',
                    marginTop: '40px',
                    padding: '10px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                <Typography variant="h6" sx={{ color: 'black', textAlign: 'center' }}>
                    Sigamos en contacto.
                </Typography>
                <Typography variant="body2" sx={{ color: 'black', textAlign: 'center' }}>
                    Más juntos que nunca
                </Typography>

                <form onSubmit={onSubmit} >
                    <Box sx={{ display: 'flex', color: 'white', justifyContent: 'center', alignItems: 'center', m: 2, gap: 2, flexDirection: 'column' }}>
                        <TextField
                            required
                            id="name"
                            variant="standard"
                            label="Nombre"
                            type="text"
                            name="name"
                            sx={{ width: '80%', color: 'white' }}
                            inputProps={{ style: { '& .MuiFormLabel-root ': { color: 'white' } } }}
                        />
                        <TextField
                            required
                            id="lastname"
                            variant="standard"
                            label="Apellido"
                            type="text"
                            name="lastname"
                            sx={{ width: '80%' }}
                        />
                        <TextField
                            required
                            id="email"
                            variant="standard"
                            label="Correo electrónico"
                            type="email"
                            name="email"
                            sx={{ width: '80%' }}
                        />
                        <TextField
                            required
                            id="phone"
                            variant="standard"
                            label="Teléfono"
                            type="text"
                            name="phone"
                            sx={{ width: '80%' }}
                        />
                    </Box>

                    <Button variant='contained' sx={{ width: '80%', m: 3, p: 1.5, backgroundColor: '#fed104', borderRadius: '10px', color: 'black' }}
                        type="submit">Sumarme</Button>

                </form>


            </Grid>

        </Container >
    )
}

export default Banner