import './App.css';
import {  Route, Routes } from 'react-router-dom';
import Banner from './components/Banner/Banner';
import CutLinks from './components/CutLinks/CutLinks';
import Redirect from './components/Redirect/Redirect';
import TableContact from './components/Table/TableContact';


function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/:shortUrl" element={<Redirect />} />
          <Route path="/generadorDeLinks" element={<CutLinks />} />
          <Route path="/" element={<Banner />} />
          <Route path="/respuestas" element={<TableContact />} />
      </Routes>
    </div>
  );
}

export default App;
