import { initializeApp } from "firebase/app";
import { addDoc, getFirestore, doc, getDoc, collection, getDocs, updateDoc } from "firebase/firestore/lite";

import 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
};

const app = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(app);

export default firestoreDB;

export async function addContact(data) {
    const miColec = collection(firestoreDB, "contactos");
    const ordenDoc = await addDoc(miColec, data)
    return ordenDoc.id;
}

export async function cutLink(data) {
    const miColec = collection(firestoreDB, "cutLink");
    const ordenDoc = await addDoc(miColec, data)
    return ordenDoc.id;
}

export async function getLink(){
    const miColec = collection(firestoreDB, "cutLink");
    const querySnapshot = await getDocs(miColec);
    const links = [];
    querySnapshot.forEach((doc) => {
        links.push({...doc.data(), id: doc.id})
    });
    return links;
}

export async function viewContact() {

    const miColec = collection(firestoreDB, "contactos");
    const querySnapshot = await getDocs(miColec)
    return querySnapshot.docs.map(doc =>{
        return{
        ...doc.data()
        }});
}

