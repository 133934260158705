import React from 'react'
import { useParams } from 'react-router-dom'
import { getLink } from '../../db/db'
import Loading from '../Loading/Loading'

const Redirect = () => {

    const params = useParams()
    getLink().then((res) => {   
        res.forEach((doc) => {
            if (doc.name === params.shortUrl) {
                window.location.href = doc.urlRedirect
            }
        })
    })

    return (
      <Loading  />
    )
}

export default Redirect