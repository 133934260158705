import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';


function appBarLabel(label) {
  return (
    <Toolbar sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }} >
{/*       <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton> */}
      <img src="/Jxc.png" alt="logo" width="100" height="50" />
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});

export default function EnableColorOnDarkAppBar() {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" sx={{backgroundColor: 'white'}}>
          {appBarLabel('JUNTOS POR EL CAMBIO')}
        </AppBar>
      </ThemeProvider>
    </Stack>
  );
}