/* eslint-disable no-unused-expressions */
import { useEffect, useState } from "react";

export const useBodyWidth = (defaultBodyWidth = 0) => {
	const [bodyWidth, setBodyWidth] = useState(defaultBodyWidth);

	useEffect(() => {
		if (typeof document !== 'undefined') {
			setBodyWidth(document.body.clientWidth);
			window.addEventListener('resize', () => {
				window.innerWidth >= 1200
					? setBodyWidth(Math.ceil(window.innerWidth))
					: null;
			});
		}
	}, []);
	return bodyWidth;
};