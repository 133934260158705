import * as React from 'react';


const Loading = () => {
    return (
        <>
            <div className="root">
                <div className="contenedor" >
                    <img src='/Jxc.jpg' alt="perrito"  className='imgLoading'/>
                </div>
            </div>

        </>

    )
}

export default Loading