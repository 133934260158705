import { Button, Container, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { cutLink, getLink } from '../../db/db'

const CutLinks = () => {

    const [shortUrl, setShortUrl] = React.useState('')
    const historyLinks = []
    getLink().then((res) => {
        res.forEach((doc) => {
            historyLinks.push(doc.name)
        })
        historyLinks.flatMap((doc) => {
            return doc
        })
    })


    const onSubmit = (e) => {
        e.preventDefault()
        const name = e.target.elements.name.value.toLowerCase().replaceAll(' ', '').replaceAll(/ /g, '').replaceAll(/á/g, 'a').replaceAll(/é/g, 'e')
            .replaceAll(/í/g, 'i').replaceAll(/ó/g, 'o')
            .replaceAll(/ú/g, 'u')
            .replaceAll(/ñ/g, 'n')
            .replaceAll(/ü/g, 'u')

        const urlRedirect = e.target.elements.urlRedirect.value
        const data = {
            name,
            urlRedirect,
        }
        if (historyLinks.includes(name)) {
            alert('El nombre de tu link ya existe')
        } else {
            cutLink(data)
            setShortUrl(`https://jxcsantafe.com.ar/${name}`)
        }
    }
    return (
        <Container maxWidth='sm'>
            <Paper
                elevation={4}
                sx={{
                    margin: 'auto',
                    marginTop: 10,
                    padding: 2,
                }}
            >
                <form onSubmit={onSubmit}>
                    <Box sx={{ display: 'flex', color: 'white', justifyContent: 'center', alignItems: 'center', m: 2, gap: 2, flexDirection: 'column' }}>
                        <TextField
                            required
                            id="name"
                            variant="standard"
                            label="Nombre"
                            type="text"
                            name="name"
                            sx={{ width: '80%', color: 'white' }}
                        />
                        <TextField
                            required
                            id="urlRedirect"
                            variant="standard"
                            label="URL Redirect"
                            type="url"
                            name="urlRedirect"
                            sx={{ width: '80%', color: 'white' }}
                        />

                    </Box>

                    <Button variant='contained' sx={{ width: '80%', m: 3, p: 1.5 }}
                        type="submit">Acortar</Button>

                </form>
                {shortUrl &&
                    <Typography variant='body2' sx={{ color: 'black' }}>
                        Se genero correctamente el link, el link generado es: {shortUrl}
                    </Typography>
                }

            </Paper>
        </Container>
    )
}

export default CutLinks